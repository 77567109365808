export default {
  state: {
    menus: {
      '/system/user/list': '用户列表',
      '/system/user/add': '用户添加',
      '/system/car/list': '车辆列表',
      '/system/car/add': '车辆添加',
      '/system/data/data': '数据',
      '/system/data/event': '事件',
      '/system/data/dict': '字典',
      '/system/data/tag': '标签',
      '/system/setting/account': '账号管理',
      '/system/setting/organize': '组织架构',
      '/system/setting/role': '角色管理',
      '/system/setting/dict': '字典管理',
      '/system/setting/menu': '菜单管理',
      '/system/setting/log': '日志查询',
      '/system/maintenanceService': '维保服务'
    },
    // 菜单折叠
    collapse: false,
    // 默认打开的子菜单
    defaultOpeneds: []
  },
  mutations: {
    setCollapse (state) {
      state.collapse = !state.collapse
    },
    updateOpeneds (state, payload) {
      if (payload.type === 'close') {
        const index = state.defaultOpeneds.findIndex(
          item => item === payload.data
        )
        if (index !== -1) {
          state.defaultOpeneds.splice(index, 1)
        }
      } else {
        state.defaultOpeneds.push(payload.data)
        state.defaultOpeneds = Array.from(new Set(state.defaultOpeneds))
      }
    },
    setDefaultOpeneds (state) {
      state.defaultOpeneds = []
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/account/login.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: store.state.sysName
    },
    component: Login
  },
  {
    path: '/system',
    name: 'system',
    meta: {
      title: store.state.sysName,
      requireAuth: true
    },
    component: () => import('@/views/system'),
    children: [
      {
        path: '',
        name: 'dashboard',
        meta: {
          title: store.state.sysName,
          requireAuth: true
        },
        component: () => import('@/views/system/dashboard')
      },
      {
        path: 'user/list',
        name: 'user/list',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/user/list.vue')
      },
      {
        path: 'user/add',
        name: 'user/add',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/user/add.vue')
      },
      {
        path: 'car/list',
        name: 'car/list',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/car/list.vue')
      },
      {
        path: 'car/add',
        name: 'car/add',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/car/add.vue')
      },
      {
        path: 'data/data',
        name: 'data/data',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/data/data.vue')
      },
      {
        path: 'data/event',
        name: 'data/event',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/data/event.vue')
      },
      {
        path: 'data/dict',
        name: 'data/dict',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/data/dict.vue')
      },
      {
        path: 'data/tag',
        name: 'data/tag',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/data/tag.vue')
      },
      {
        path: 'setting/account',
        name: 'setting/account',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/account')
      },
      {
        path: 'setting/account/record',
        name: 'setting/record',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/account/record.vue')
      },
      {
        path: 'setting/organize',
        name: 'setting/organize',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/organize')
      },
      {
        path: 'setting/role',
        name: 'setting/role',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/role')
      },
      {
        path: 'setting/dict',
        name: 'setting/dict',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/dict/index')
      },
      {
        path: 'setting/menu',
        name: 'setting/menu',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/menu')
      },
      {
        path: 'setting/log',
        name: 'setting/log',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/setting/log/index')
      },
      /**
       *
       */
      {
        path: 'maintenanceService',
        name: 'maintenanceService',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/system/maintenanceService/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta && to.meta.requireAuth) {
    const account = JSON.parse(sessionStorage.getItem('account'))
    if (!store.state.ssId && account && account.ssId) {
      store.commit('SET_SSID', account.ssId)
      store.commit('SET_SIGNKEY', account.signKey)
    }
    if (store.state.ssId) {
      next()
    } else {
      next('')
    }
  } else {
    next()
  }
})

export default router

export default {
  state: {
    dataArray: [], // 全量表格列表数据
    isDraging: false, // 是否在拖拽中

    dragPid: null,
    dragDepartmentId: null,
  },
  mutations: {
    SET_DATA_ARRAY(state, payload) {
      state.dataArray = payload
    },
    SET_IS_DRAGING(state, payload) {
      state.isDraging = payload
    },
    SET_DRAG_INFO(state, payload) {
      state.dragPid = payload.dragPid
      state.dragDepartmentId = payload.dragDepartmentId
    },
  },
}

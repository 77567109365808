import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import tab from './tab'
import menu from './menu'
import organize from './organize'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ssId: '',
    signKey: '',
    sysName: '',
    tenantId: null,
    welcomeImageUrl: '',
    requestLoading: false,
  },
  mutations: {
    SET_SSID(store, payload) {
      store.ssId = payload
    },
    SET_SIGNKEY(store, payload) {
      store.signKey = payload
    },
    SET_LOADING(state, payload) {
      state.requestLoading = payload
    },
    SET_TID(state, payload = null) {
      state.tenantId = payload
    },
    SET_GID(state, payload) {
      state.groupId = payload
    },
    SET_SYS_NAME(state, payload = '多租户管理系统') {
      state.sysName = payload
    },
    SET_WELCOME_IMAGE(state, payload) {
      state.welcomeImageUrl = payload
    },
  },
  actions: {
    // 注销
    logOut({ commit }, callback) {
      commit('SET_SSID', '')
      commit('SET_SIGNKEY', '')
      commit('tabDelMul') // 删除所有tab页面
      commit('setActiveIndex', -1) // 设置激活的tab页面
      commit('setDefaultOpeneds') // 折叠所有菜单
      sessionStorage.setItem('account', null)
      callback && callback()
      location.reload()
    },
  },
  modules: {
    tab,
    menu,
    organize,
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
})

import Vue from 'vue'
import axios from 'axios'
import App from '../App.vue'
import store from '../store'
import router from '../router'
import { MessageBox } from 'element-ui'

// 加密依赖
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import hmacSHA256 from 'crypto-js/hmac-sha256'

axios.defaults.timeout = 20000

axios.get('/static/json/apiConfig.json').then((res) => {
  axios.interceptors.request.use(
    (config) => {
      try {
        const evn = process.env.NODE_ENV
        if (evn !== 'development') {
          const targetArr = config.url.split('/')
          const baseUrl = res.data.apiEx
          // console.log('baseUrl', baseUrl)
          config.url = baseUrl + targetArr.slice(2).join('/')
        }
      } catch (err) {
        console.log('解析环境配置文件出现异常', err)
      }

      // filterData方法必须再setHeader方法之前调用
      filterData(config.data)
      setHeader(config)

      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  window.app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
})

/**
 * 包裹 promise 处理错误
 * @date 2021-04-08
 * @param {promise} promise
 * @returns {promise}
 */
function wrap(promise) {
  return promise.then((data) => [null, data]).catch((err) => [err, null])
}

/**
 * 基础post请求封装
 * @date 2020-04-08
 * @param {string} url 地址
 * @param {object} params 参数
 * @param {object} config 配置参数
 * @returns {promise} 请求返回值
 */
function innerPost(url, params, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then((res) => {
        const {
          data: { data, code, message },
        } = res
        if (code === 0) {
          resolve(data)
          return
        }
        reject({ type: 'error', code, message, originInfo: res }) // eslint-disable-line prefer-promise-reject-errors
      })
      .catch((error) => {
        const obj = {
          type: 'exception',
          originInfo: error,
          message: '网络请求失败，请刷新重试！',
        }
        reject(obj) // eslint-disable-line prefer-promise-reject-errors
      })
  })
}

/**
 * post请求
 * @date 2020-11-27
 * @param {string} url 地址
 * @param {object} options 参数
 * @returns {promise} 请求返回值
 */
export async function post(url, options = {}, config = {}) {
  const { extra, ...params } = options || {}
  store.commit('SET_LOADING', true)
  const [error, data] = await wrap(innerPost(url, params, config))
  store.commit('SET_LOADING', false)
  // 接口正常
  if (!error) {
    return data
  }

  // 接口出错
  if (extra && typeof extra.errorcallback === 'function') {
    // 自定义处理错误
    extra.errorcallback(error)
    return false
  }

  const { code = null, message } = error
  // 没有自定义错误 默认弹窗提示
  errorTips(code, message)

  return 'fail&&error'
}

function setHeader(config) {
  if (
    store &&
    store.state &&
    store.state.ssId &&
    store.state.ssId !== undefined
  ) {
    const timestamp = parseInt(new Date().getTime() / 1000)
    const nonce = getNonce(timestamp)
    const signature = getSignature(
      store.state.signKey,
      timestamp,
      nonce,
      config.data
    )
    config.headers['X-Nokey-Nonce'] = nonce
    config.headers['X-Nokey-Signature'] = signature
    config.headers['X-Nokey-Timestamp'] = timestamp
    config.headers['X-Nokey-ssId'] = store.state.ssId
  }
}

// 去除字符串的前后空格
function filterData(data = {}) {
  // 如果不是对象类型，直接返回
  if (Object.prototype.toString.call(data) !== '[object Object]') return

  for (const key in data) {
    // 如果是字符串，去除前后空格
    if (Object.prototype.toString.call(data[key]) === '[object String]') {
      data[key] = data[key].trim()
    } else if (
      Object.prototype.toString.call(data[key]) === '[object Object]'
    ) {
      filterData(data[key])
    }
  }
}

function errorTips(code, message, callback) {
  MessageBox.alert(message, '错误', {
    dangerouslyUseHTMLString: true,
    type: 'error',
    customClass: 'errorTips',
    confirmButtonText: '确定',
    confirmButtonClass: '',
    callback: (action) => {
      if (parseInt(code) === 15100012) {
        store.dispatch('logOut')
      } else {
        callback && callback()
      }
    },
  })
}

function getNonce(timestamp) {
  const radom = Math.random()
  var nonce = Base64.stringify(sha256((timestamp + radom).toString()))
  return nonce
}

function getSignature(signKey, timestamp, nonce, body = {}) {
  // 签名数据中有某key值为对象时，需要删除
  Object.keys(body).forEach((item) =>
    Object.prototype.toString.call(body[item]) === '[object Object]'
      ? delete body[item]
      : ''
  )
  const conBody = Object.assign(body, { nonce: nonce, timestamp: timestamp })
  const sortBody = sortObject(conBody)
  const paramsArray = []

  for (var key in sortBody) {
    const value = sortBody[key]
    if (value instanceof Array) {
      paramsArray.push(key + '=')
    } else if (
      typeof value !== 'object' &&
      !Number.isNaN(value) &&
      typeof value !== 'undefined'
    ) {
      paramsArray.push(key + '=' + value)
    }
  }

  const signKeyObj = Base64.parse(signKey)
  const paramsStr = paramsArray.join('&')
  const signature = Base64.stringify(hmacSHA256(paramsStr, signKeyObj))
  // console.log("防重放================");
  // console.log('sortbody', sortBody)
  // console.log('signKey', signKey)
  // console.log("timestamp",timestamp);
  // console.log("nonce",nonce);
  // console.log("body",JSON.stringify(body));
  // console.log("&&&&&&")
  // console.log('paramsStr', paramsStr)
  // console.log('signKeyObj', signKeyObj)
  // console.log('signature', signature)
  // console.log("================");
  return signature
}

function sortObject(bodyObj) {
  const orderBody = {}
  Object.keys(bodyObj)
    .sort()
    .forEach(function (key) {
      orderBody[key] = bodyObj[key]
    })
  return orderBody
}

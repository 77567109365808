export default {
  state: {
    tabArray: [],
    activeIndex: -1
  },
  mutations: {
    tabAdd (state, payload) {
      state.tabArray = [...state.tabArray, payload]
      state.activeIndex = state.tabArray.length - 1
    },
    tabDel (state, payload) {
      state.tabArray.splice(payload, 1) // 删除一项

      if (state.tabArray.length === 0) {
        state.activeIndex = -1
      } else {
        state.activeIndex = payload === 0 ? 0 : payload - 1
      }
    },
    tabDelMul (state, payload) {
      switch (payload) {
        case 'left':
          state.tabArray.splice(0, state.activeIndex)
          state.activeIndex = 0
          break
        case 'right':
          state.tabArray.splice(
            state.activeIndex + 1,
            state.tabArray.length - 1
          )
          break
        case 'other':
          state.tabArray = state.tabArray.splice(state.activeIndex, 1)
          state.activeIndex = 0
          break
        default:
          state.tabArray = []
          state.activeIndex = -1
          break
      }
    },
    setActiveIndex (state, payload) {
      state.activeIndex = payload
    }
  }
}

function numFormater (v) {
  return v < 10 ? '0' + v : v
}

export const dateFormat = (date = new Date()) => {
  date = new Date(date)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const min = date.getMinutes()
  const sec = date.getSeconds()

  return `${year}-${numFormater(month)}-${numFormater(day)} ${numFormater(
    hour
  )}:${numFormater(min)}:${numFormater(sec)}`
}

export const getResultByKey = (arr, key, feild = 'value') => {
  const result = arr.find(item => item.key === key)
  return result ? result[feild] : '-'
}

export const getStringByteLength = str => {
  if (!str) {
    return 0
  }
  str = str.trim()
  let len = 0
  for (let i = 0; i < str.length; i++) {
    // 正则表达式判断中文
    if (/[\u4e00-\u9fa5]/.test(str[i])) {
      len += 2
    } else {
      len++
    }
  }
  return len
}

export const validateFeildLength = length => {
  return (rule, value, callback) => {
    if (getStringByteLength(value) > length * 2) {
      callback(new Error(`字数超出限制，${length}个中文字或${length * 2}个英文字`))
    } else {
      callback()
    }
  }
}

export const validateMobile = (rule, value, callback) => {
  const reg = /^[1][0-9]{10}$/ // 1开头的11位数字
  if (!value) {
    callback(new Error('请输入手机号'))
  } else if (!reg.test(value)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback()
  }
}

<template>
  <div class="page login-page">
    <div class="login-area">
      <div class="container flex">
        <div class="left" v-loading="$store.state.requestLoading">
          <div class="flex ai-center" style="color: #929292; font-size: 1.5rem">
            <i class="login-logo"></i>
            {{ $store.state.sysName }}
          </div>
          <el-form
            style="margin-top: 6rem"
            :model="loginForm"
            :rules="rules"
            ref="loginForm"
          >
            <el-form-item prop="phone">
              <el-input
                clearable
                v-model="loginForm.phone"
                size="medium"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="useCaptchaLogin" prop="captcha">
              <el-input
                clearable
                size="medium"
                placeholder="请输入验证码"
                v-model="loginForm.captcha"
                style="width: 63%; margin-right: 3%"
                @keyup.enter.native="submit"
              ></el-input>
              <div
                :class="[
                  'pointer',
                  'captcha',
                  btnText !== '获取验证码' && 'disabled',
                ]"
                @click="sendSmsCode"
              >
                {{ btnText }}
              </div>
            </el-form-item>
            <el-form-item v-else prop="password">
              <el-input
                clearable
                size="medium"
                type="password"
                placeholder="请输入密码"
                v-model="loginForm.password"
                @keyup.enter.native="submit"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div
                class="text-small flex ai-center jc-between"
                style="line-height: 1.2; padding: 0.6rem 0 0.38rem 0"
              >
                <span>
                  <el-checkbox
                    style="margin-right: 0.38rem"
                    v-model="checked"
                  ></el-checkbox
                  >我已阅读接受<span style="color: #337df7" class="pointer"
                    >服务条款</span
                  >
                </span>
                <span
                  class="pointer"
                  style="color: #2e9bff"
                  @click="switchLoginMethod()"
                >
                  {{ useCaptchaLogin ? '使用密码登录' : '使用验证码登录' }}
                </span>
              </div>
              <div class="center pointer login-submit" @click="submit()">
                登录
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="right"></div>
      </div>
      <div class="footer center text-small">
        © 2021-2025 景舍科技 沪ICP备2020028172号
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      // 使用验证码登录
      useCaptchaLogin: true,
      // 同意服务协议
      checked: process.env.NODE_ENV === 'development',
      // 登录表单数据
      loginForm: {
        phone:
          process.env.NODE_ENV === 'development'
            ? localStorage.getItem('phone') || ''
            : '',
        captcha: process.env.NODE_ENV === 'development' ? '117997' : '',
        password: '',
      },
      btnText: '获取验证码',
      rules: {
        phone: [
          { validator: this.$util.validateMobile, trigger: ['change', 'blur'] },
        ],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    }
  },
  beforeCreate() {
    if (this.$route.query.accountInfo) {
      sessionStorage.setItem(
        'account',
        decodeURIComponent(this.$route.query.accountInfo)
      )
    }
    const account = JSON.parse(sessionStorage.getItem('account')) || {}
    if (account && account.ssId) {
      this.$router.push('/system')
    }

    // 存储租户id：tenantId
    console.log(this.$route.query)
    this.$store.commit(
      'SET_GID',
      this.$route.query.groupId || this.$store.state.groupId
    )
  },
  created() {
    this.getSysName()
  },
  methods: {
    async getSysName() {
      const res = await this.$api.getSysName({
        domain: `${window.location.protocol}//${window.location.host}`,
      })
      if (res !== 'fail&&error') {
        this.$store.commit('SET_SYS_NAME', res.sysName)
        this.$store.commit('SET_TID', res.tenantId)
        this.$store.commit('SET_WELCOME_IMAGE', res.welcomeImageUrl)
      } else {
        this.$store.commit('SET_SYS_NAME')
        this.$store.commit('SET_TID')
        this.$store.commit('SET_WELCOME_IMAGE')
      }
    },
    // 获取短信验证码
    sendSmsCode() {
      if (this.btnText !== '获取验证码') {
        return
      }
      this.$refs.loginForm.validateField('phone', async (errMsg) => {
        if (!errMsg) {
          let res = await this.$api.sendSmsCode({
            phone: this.loginForm.phone,
            tenantId: this.$store.state.tenantId,
          })
          res = JSON.parse(res)

          if (res !== 'fail&&error') {
            this.btnText = '59s后重新获取'
            let ss = 59
            const timer = setInterval(() => {
              ss--
              if (ss === 0) {
                clearInterval(timer)
                this.btnText = '获取验证码'
              } else {
                this.btnText = `${ss}s后重新获取`
              }
            }, 1000)
          }
        }
      })
    },
    submit() {
      if (!this.checked) {
        return this.$message({
          type: 'success',
          message: '请同意并勾选服务协议',
        })
      }

      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          process.env.NODE_ENV === 'development' &&
            localStorage.setItem('phone', this.loginForm.phone)

          // 发送请求
          var res = false
          if (this.useCaptchaLogin) {
            // 验证码登录
            res = await this.$api.loginWithCaptcha({
              phone: this.loginForm.phone,
              captcha: this.loginForm.captcha,
              tenantId: this.$store.state.tenantId,
            })
          } else {
            // 密码登录
            res = await this.$api.loginWithPwd({
              phone: this.loginForm.phone,
              password: CryptoJS.MD5(this.loginForm.password).toString(),
              tenantId: this.$store.state.tenantId,
            })
          }
          if (res !== 'fail&&error') {
            sessionStorage.setItem('account', JSON.stringify(res))
            this.$router.push('/system')
          }
        }
      })
    },

    switchLoginMethod() {
      this.loginForm.captcha = ''
      this.loginForm.password = ''
      this.$refs.loginForm.clearValidate(['captcha', 'password'])
      this.useCaptchaLogin = !this.useCaptchaLogin
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  min-width: 66rem;
  min-height: 38rem;
  background-size: 100% 100%;
  background-image: url('../../assets/img/login-bg.png');

  .login-area {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 38rem;
    background-color: #fff;
    transform: translate(-50%, -50%);

    .container {
      height: 34.5rem;
      .left {
        width: 22rem;
        background: #fff;
        padding: 7.2rem 2rem 0 2rem;
        // .el-form-item {
        //   margin-bottom: 0.3rem;
        // }
        .login-logo {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.38rem;
          background-size: cover;
          background-image: url('../../assets/img/invalid-name@3x.png');
        }
        .captcha {
          width: 34%;
          color: #fff;
          height: 2.2rem;
          font-size: 0.8rem;
          text-align: center;
          line-height: 2.2rem;
          display: inline-block;
          background: #337df7;
          &.disabled {
            background-color: #ccc;
          }
        }
        .login-submit {
          width: 100%;
          color: #fff;
          height: 2.2rem;
          background-color: #337df7;
          box-shadow: 0 8px 15px #337ef7b7;
        }
      }
      .right {
        width: 44rem;
        background-size: 100% 100%;
        background-image: url('../../assets/img/login.png');
      }
    }

    .footer {
      height: 3.5rem;
      color: #aeaeae;
    }
  }
}
</style>

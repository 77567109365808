import { post } from './request'

// 获取验证码
export async function sendSmsCode (payload = {}) {
  // return await post('/apiEx/user/p/v2/sms/send', payload)
  return await post('/apiEx/tenant/tenant-oms/v1/public/sms/send', payload)
}
// 10.01 - 用户手机号验证码登录 - S21.5
export async function loginWithCaptcha (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/login', payload)
}
// 10.02 - 用户手机号密码登录 - S21.5
export async function loginWithPwd (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/login-pwd', payload)
}
// 10.03 - 用户登出 - S21.5
export async function logout (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/logout', payload)
}
// 20.01 - 部门列表查询 - S21.5
export async function departmentList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/department/list', payload)
}
// 20.02 - 创建部门 - S21.5
export async function departmentSave (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/department/save', payload)
}
// 20.03 - 编辑部门 - S21.5
export async function departmentUpdate (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/department/update', payload)
}
// 20.04 - 删除部门 - S21.5
export async function departmentDelete (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/department/delete', payload)
}
// 30.11 - 查询租户系统名称 - S21.5
export async function getSysName (payload = {}) {
  // return await post('/apiEx/tenant/tenant-oms/v1/tenant/sys-name', payload)
  return await post('/apiEx/tenant/tenant-oms/v2/tenant/sys-name', payload)
}
// 40.11 - 添加字典 - S22.5
export async function saveDict (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/save', payload)
}
// 40.12 - 查询字典详情 - S22.5
export async function getDictdetail (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/detail', payload)
}
// 40.13 - 右边字典项列表带分页 - S22.5
export async function getRightDictList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/page', payload)
}
// 40.14 - 岗位字典项列表 - S22.5
export async function getDictList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/list', payload)
}
// 40.15 - 批量删除字典 - S22.5
export async function deleteDict (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/delete', payload)
}
// 40.16 - 左边字典管理列表 - S22.5
export async function getLeftDictList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/type/list', payload)
}
// 40.17 - 更新字典 - S22.5
export async function updateDict (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/dict/update', payload)
}
// 40.11 - 菜单列表查询 - S22.5
export async function getResourceList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/resource/list', payload)
}
// 40.12 - 创建菜单 - S22.5
export async function saveResource (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/resource/save', payload)
}
// 40.13 - 编辑菜单 - S22.5
export async function updateResource (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/resource/update', payload)
}
// 40.14 - 删除菜单 - S22.5
export async function deleteResource (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/resource/delete', payload)
}
// 40.15 - 上传菜单图标 - S22.5
export async function uploadIcon (payload = {}, config = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/resource/icon-upload', payload, config)
}
// 40.16 - 保存拖拽后的菜单项 - S22.5
export async function sortResource (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/resource/save-menu', payload)
}
// 50.01 - 所有角色列表 - S22.5
export async function getAccountRoleList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/role/list', payload)
}
// 50.02 - 角色列表带分页查询 - S22.5
export async function getRoleList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/role/page', payload)
}
// 50.03 - 获取角色详情 - S22.5
export async function getRoleDetail (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/role/detail', payload)
}
// 50.04 - 创建角色 - S22.5
export async function createRole (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/role/save', payload)
}
// 50.05 - 编辑角色 - S22.5
export async function updateRole (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/role/update', payload)
}
// 50.06 - 批量删除角色 - S22.5
export async function deleteRole (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/role/delete', payload)
}
// 60.01 - 用户查询 - S22.5
// export async function operatorList (payload = {}) {
//   return await post('/apiEx/tenant/tenant-oms/v1/operator/list', payload)
// }
// 60.02 - 创建用户 - S22.5
export async function operatorSave (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/save', payload)
}
// 60.03 - 批量启用停用 - S22.5
export async function updateStatus (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/update-status', payload)
}
// 60.04 - 批量删除用户 - S22.5
export async function deleteOperator (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/delete', payload)
}
// 60.05 - 修改用户信息 - S22.5
export async function updateOperator (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/update', payload)
}
// 60.06 - 用户查询 - S22.5
export async function pageOperator (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/page', payload)
}
// 60.07 - 用户详情  - S22.5
export async function detailOperator (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/detail', payload)
}
// 60.08 - 获取用户数据权限  - S22.5
export async function dataRights (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/data-rights', payload)
}
// 60.09 - 保存用户数据权限  - S22.5
export async function saveRights (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/data-rights/save', payload)
}
// 60.10 - 下载账号批量导入模板  - S22.5
export async function downloadTemplate (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/download-template', payload)
}
// 60.11 -  账号导入  - S22.5
export async function operatorImport (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/import', payload)
}
// 60.12 -  账号导入记录  - S24.1
export async function importLog (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/import-log/page', payload)
}
// 70.11 -  登陆用户的菜单列表  - S22.5
export async function menusList (payload = {}) {
  // return await post('/apiEx/tenant/tenant-oms/v1/operator/menus', payload)
  return await post('/apiEx/tenant/tenant-oms/v1/public/operator/menus', payload)
}
// 80.11登录日志
export async function loginList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/login-log/page', payload)
}
// 80.12操作日志
export async function logList (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/operator/log/page', payload)
}
// 80.13 用户密码个人信息
export async function simpleInfo (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/public/operator/simple-info', payload)
}
// 80.14 修改密码
export async function modifyPwd (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/public/operator/modify-pwd', payload)
}
// 查询预约的保养记录 - S15
export async function queryMaintenanceOrderRecord (payload = {}) {
  return await post('/apiEx/ecology/tenant-oms/v1/queryMaintenanceOrderRecord', payload)
}

// 20.05 - 改变部门层级 - S21.5
export async function changeHierarchy (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/department/changeHierarchy', payload)
}

// 20.05 - 部门排序 - S21.5
export async function departmentSort (payload = {}) {
  return await post('/apiEx/tenant/tenant-oms/v1/department/sort', payload)
}

<template>
  <router-view />
</template>

<script>
export default {
  watch: {
    '$route.path' (newVal, oldVal) {
      if (newVal === '/system') {
        this.$store.commit('setActiveIndex', -1)
      }
    },
    '$store.state.sysName' (newVal, oldVal) {
      if (newVal) {
        document.title = newVal
      }
    }
  }
}
</script>

import Vue from 'vue'

// 全局reset && 公共样式
import './assets/scss/reset.scss'
import './assets/scss/common.scss'

// element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// element-ui样式重写 && iconfont小图标
import './assets/scss/element.scss'
import './assets/iconfont/iconfont.css'

// util公告方法 && api接口
import * as util from './utils/util'
import * as api from './utils/api' // 这里入口
Vue.prototype.$util = util
Vue.prototype.$api = api

Vue.use(ElementUI)

Vue.config.productionTip = false
